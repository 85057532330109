<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" :close-on-click-modal="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="门店类型" prop="shop_type">
                    <el-radio-group v-model="ruleForm.shop_type">
                        <el-radio :label="'1'">主门店</el-radio>
                        <el-radio :label="'2'">子门店</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="门店LOGO" prop="logo">
                    <el-upload style="width:80%" :show-file-list="false" :auto-upload="false" class="upload-demo" action="#" :on-change="handleChange">
                        <div class="flex">
                            <div>
                                <el-button size="small" type="primary">点击上传</el-button>
                            </div>
                            <div class="image_bos" v-if="ruleForm.logo">
                                <div class="image_list">
                                    <img class="img" :src="BASE_IMG_URL +ruleForm.logo" alt="">
                                    <div class="demo_cover flex-row-center cursor-in">
                                        <i class="el-icon-remove-outline" style="color:#ffffff;font-size:24px" @click="handleRemove()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb;建议尺寸：70*70p</div>
                    </el-upload>
                </el-form-item>
                <el-form-item v-if="ruleForm.shop_type == 1" label="归属用户" prop="account_id">
                    <el-select style="width: 400px" v-model="ruleForm.account_id" filterable placeholder="请选择归属用户">
                        <el-option v-for="(item,index) in accountList" :key="index" :label="item.username" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="ruleForm.shop_type == 2" label="上级门店" prop="pid">
                    <el-select style="width: 400px" v-model="ruleForm.pid" filterable placeholder="请选择上级门店">
                        <el-option @click.native="chPid(item)" v-for="(item,index) in pidList" :key="index" :label="item.abbreviation" :value="item.shop_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="门店编号" prop="shop_number">
                    <el-input style="width: 400px" v-model="ruleForm.shop_number" placeholder="请输入门店编号"></el-input>
                </el-form-item>
                <el-form-item label="门店全称" prop="name">
                    <el-input style="width: 400px" v-model="ruleForm.name" placeholder="请输入门店全称"></el-input>
                </el-form-item>
                <el-form-item label="门店简称" prop="abbreviation">
                    <el-input style="width: 400px" v-model="ruleForm.abbreviation" placeholder="请输入门店简称"></el-input>
                </el-form-item>
                <el-form-item label="连锁代码" prop="shop_code">
                    <el-input :disabled="ruleForm.shop_type == 2?true:false" style="width: 400px" v-model="ruleForm.shop_code" placeholder="请输入连锁代码"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="mobile">
                    <el-input style="width: 400px" v-model="ruleForm.mobile" placeholder="请输入电话"></el-input>
                </el-form-item>
                <el-form-item label="城市" prop="address">
                    <el-cascader style="width: 400px" v-model="citydata" :options="cityList"></el-cascader>
                </el-form-item>
                <el-form-item label="地址" prop="address">
                    <el-input style="width: 400px" v-model="ruleForm.address" placeholder="请输入地址"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="level">
                    <el-radio-group v-model="ruleForm.level">
                        <el-radio-button label="1">总部</el-radio-button>
                        <el-radio-button label="2">事业部</el-radio-button>
                        <el-radio-button label="3">地区</el-radio-button>
                        <el-radio-button label="4">区域</el-radio-button>
                        <el-radio-button label="5">门店</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="有效期" prop="yxqtime">
                    <el-date-picker placeholder="请选择有效期" style="width: 400px" value-format="yyyy-MM-dd" v-model="ruleForm.yxqtime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="使用类型" prop="status">
                    <el-radio-group v-model="ruleForm.status">
                        <el-radio :label="'1'">已经授权</el-radio>
                        <el-radio :label="'-1'">取消授权</el-radio>
                        <el-radio :label="'0'">试用门店</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="经营状态" prop="busines_status">
                    <el-radio-group v-model="ruleForm.busines_status">
                        <el-radio :label="'1'">正在经营</el-radio>
                        <el-radio :label="'0'">停止经营</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button :loading="loading" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {

    data() {
        return {
            BASE_IMG_URL:window.global_config.BASE_IMG_URL,
            id: null,
            type: '',
            title: '',
            accountList: [],
            citydata: [],
            cityList: [],
            pidList: [],
            dialogVisible: false,
            loading: false,
            ruleForm: {
                shop_type: '1', logo: '', account_id: '', shop_number: '', address: '',
                name: '', abbreviation: '', shop_code: '', mobile: '', pid: '',
                level: '1', start_time: '', end_time: '', busines_status: '1', status: '1',
                yxqtime: []
            },
            rules: {
                shop_type: [
                    { required: true, message: '请选择门店类型', trigger: 'blur' }
                ],
                logo: [
                    { required: true, message: '请上传门店LOGO', trigger: 'blur' }
                ],
                account_id: [
                    { required: true, message: '请选择归属用户', trigger: 'change' }
                ],
                pid: [
                    { required: true, message: '请选择上级门店', trigger: 'change' }
                ],
                shop_number: [
                    { required: true, message: '请输入门店编号', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入门店全称', trigger: 'blur' }
                ],
                abbreviation: [
                    { required: true, message: '请输入门店简称', trigger: 'blur' }
                ],
                shop_code: [
                    { required: true, message: '请输入连锁代码', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入电话', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入地址', trigger: 'blur' }
                ],
                level: [
                    { required: true, message: '请选择门店级别', trigger: 'blur' }
                ],
                yxqtime: [
                    { required: true, message: '请选择有效期', trigger: 'change' }
                ],
                status: [
                    { required: true, message: '请选择使用类型', trigger: 'change' }
                ],
                busines_status: [
                    { required: true, message: '请选择经营状态', trigger: 'change' }
                ],
            }
        }
    },
    created() {
        this.getLists()
        this.getPid()
        this.getcityList()
    },
    methods: {
        open(type, row) {
            this.type = type
            if (type == 'add') {
                this.title = '新增门店'
                this.ruleForm = {
                    shop_type: '1', logo: '', account_id: '', shop_number: '', address: '',
                    name: '', abbreviation: '', shop_code: '', mobile: '', pid: '',
                    level: '1', start_time: '', end_time: '', busines_status: '1', status: '1',
                    yxqtime: []
                }
                this.citydata = []
            } else {
                const {
                    shop_type, logo, account_id, shop_number, address,
                    name, abbreviation, shop_code, mobile, pid,
                    level, start_time, end_time, busines_status, status,
                } = row
                const data = {
                    shop_type, logo, account_id, shop_number, address,
                    name, abbreviation, shop_code, mobile, pid,
                    level, start_time, end_time, busines_status, status,
                }
                data.shop_type = data.shop_type + ''
                data.status = data.status + ''
                data.busines_status = data.busines_status + ''
                data.yxqtime = [row.start_time, row.end_time]
                this.citydata = [row.province, row.city]
                this.id = row.shop_id
                if (row.area) {
                    this.citydata.push(row.area)
                }
                this.ruleForm = data
                this.title = '编辑门店'
            }
            this.dialogVisible = true
        },
        chPid(res) {
            this.ruleForm.shop_code = res.shop_code
        },
        getPid() {
            this.$http
                .api("api/store/listStore", {
                    // app_id: this.$store.state.test.app_id,
                    status_type: '1',
                    limit: 100,
                    page: 1,
                })
                .then((res) => {
                    this.pidList = res.data.data;
                })
        },
        getLists() {
            this.$http.api('api/user/ListUser', {
                status: '',
                limit: 100,
                page: 1,
            }).then(res => {
                this.accountList = res.data.data;
            })
        },
        getcityList() {
            this.$http.api('api/common/getArea', {}).then(res => {
                res.data.forEach(item1 => {
                    item1.value = item1.areacode
                    item1.label = item1.name
                    item1.children.forEach(item2 => {
                        item2.value = item2.areacode
                        item2.label = item2.name
                        item2.children.forEach(item3 => {
                            item3.value = item3.areacode
                            item3.label = item3.name
                        })
                    })
                });
                this.cityList = this.handleTreeList(res.data)
            })
        },
        handleTreeList(list) { // 删除第三级children
            for (var i = 0; i < list.length; i++) {
                if (list[i].children.length < 1) {				// 判断children的数组长度
                    list[i].children = undefined
                } else {
                    this.handleTreeList(list[i].children)
                }
            }
            return list
        },
        //上传图片
        handleChange(file) {
            const isAccess = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt4M = file.size / 1024 / 1024 < 4;
            if (!isAccess) {
                return this.$message.error('上传图片只能是 JPG或PNG 格式!');
            }
            if (!isLt4M) {
                return this.$message.error('上传头像图片大小不能超过 4MB!');
            }
            let params = new FormData();  //   这步是很关键的，创建一个空对象赋值给fileNames
            params.append("file", file.raw);
            params.append("file_name", file.name);
            this.$http.upload(file.raw).then(res => {
                this.ruleForm.logo = res.data.url
            })
        },
        handleRemove() {
            this.ruleForm.logo = ''
        },
        submitForm(formName) {
            console.log(this.ruleForm)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let obj = this.ruleForm
                    obj.province = this.citydata[0]
                    obj.city = this.citydata[1]
                    obj.area = this.citydata[2] ? this.citydata[2] : ''
                    obj.start_time = obj.yxqtime[0]
                    obj.end_time = obj.yxqtime[1]
                    obj.app_id = this.$store.state.test.app_id
                    obj.source = '1'
                    if (obj.shop_type == 1) {
                        obj.pid = ''
                    } else {
                        obj.account_id = ''
                    }
                    let url = 'api/store/addStore'
                    if (this.id) {
                        obj.id = this.id
                        url = 'api/store/editStore'
                    }
                    this.loading = true
                    this.$http.api(url, obj).then(res => {
                        this.dialogVisible = false
                        this.$emit('ok')
                        this.loading = false;
                    }).catch(res => {
                        this.loading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleClose() {
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-upload--picture-card {
    width: 60px;
    height: 60px;
    line-height: 65px;
}
.image_bos {
    .image_list {
        height: 70px;
        width: 70px;
        border-radius: 5px;
        overflow: hidden;
        display: inline-block;
        margin-left: 10px;
        position: relative;
        .img {
            height: 70px;
            width: 70px;
        }
        .demo_cover {
            position: absolute;
            top: 0;
            left: 0;
            height: 0px;
            width: 0px;
            background: rgba(0, 0, 0, 0.6);
            overflow: hidden;
            cursor: pointer;
        }
    }
    .image_list:hover {
        .demo_cover {
            height: 70px;
            width: 70px;
        }
    }
}
.el-upload__tip {
    margin-top: 0px;
}
</style>
